import { ContentTitler } from '@property-folders/components/dragged-components/ContentTitler';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AuthApi } from '@property-folders/common/client-api/auth';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useEntitySettingsParams } from '~/pages/settings/config';
import { ShortId } from '@property-folders/common/util/url';
import { Lookups } from '@property-folders/common/client-api/lookups';
import { SetupEntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { YManagerContext } from '@property-folders/components/context/YManagerContext';
import { Predicate } from '@property-folders/common/predicate';
import { useLocalStorage } from 'react-use';
import { byMapperFn } from '@property-folders/common/util/sortComparison';
import clsJn from '@property-folders/common/util/classNameJoin';

interface SelectedEntity { id: number, uuid: string }

export function EntitySettingsEntitySelectPage(props: React.PropsWithChildren) {
  const navigate = useNavigate();
  const { entityUuid, settingsGroupTabConfig, mobile, largeScreen } = useEntitySettingsParams();
  const portalableNavRef = useRef<HTMLDivElement>(null);

  return <ContentTitler
    title={settingsGroupTabConfig?.title || 'Agency Settings'}
    flex
    scroll
    afterTitle={<>
      <div
        className={clsJn({
          'flex-grow-1 ms-auto': !mobile,
          'w-100': mobile
        })}
        style={{ maxWidth: '400px' }}
      >
        <ManageEntitySelector
          selected={entityUuid}
          onSelect={entity => entity?.uuid
            ? navigate(`${ShortId.fromUuid(entity.uuid)}/${settingsGroupTabConfig?.id}`)
            : navigate('')}
        />
      </div>
    </>}
    breadcrumbs={largeScreen ? [] : [
      { label: 'Agency Settings', href: entityUuid ? `/agency-settings/${ShortId.fromUuid(entityUuid)}` : undefined },
      settingsGroupTabConfig ? { label: settingsGroupTabConfig.title } : undefined
    ].filter(Predicate.isNotNull)}
    afterBreadcrumbs={<><div ref={portalableNavRef} /></>}
  >
    <SetupEntitySettingsContext
      settingsGroupTabConfig={settingsGroupTabConfig}
      entityUuid={entityUuid}
      largeScreen={largeScreen}
      afterBreadcrumbs={portalableNavRef.current}
    >
      {props.children}
    </SetupEntitySettingsContext>
  </ContentTitler>;
}

interface EntitySelectorProps {
  selected: string | undefined,
  onSelect: (entity?: SelectedEntity) => void
}
function ManageEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const isGlobalAdmin = sessionInfo?.isGlobalAdmin ?? false;

  return isGlobalAdmin
    ? <OnlineEntitySelector {...props}/>
    : <ManagerEntitySelector {...props}/>;
}

// Select's default behaviour is to render items with a value and a label.
// If we need to show separate things like business name, trading name, and RLA,
// we will need to change this type and the control to render accordingly
interface EntityOption {
  value: string;
  label: string;
}

function OnlineEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { instance: yManager } = useContext(YManagerContext);
  const [options, setOptions] = useState<EntityOption[]>([]);
  const [entities, setEntities] = useState(new Map<string, number>());
  const [removeUuids] = useState(new Set<string>());
  const [rememberUuid, setRememberUuid] = useLocalStorage<string>('EntitySettingsSelectedEntityUuid', undefined, { raw: true });

  useEffect(() => {
    const { ac, results } = Lookups.lookupEntities({
      canManage: true
    });

    results
      .then(data => {
        if (!data) return;
        setOptions(data.items.map(i => ({
          value: i.entityUuid,
          label: i.name
        })));
        setEntities(new Map(data.items.map(i => [i.entityUuid, i.entityId])));
      })
      .catch(console.error);

    return () => {
      ac.abort();
    };
  }, []);

  const onSelect = useCallback((entity?: SelectedEntity) => {
    if (entity && !sessionInfo?.entities.find(e => e.entityUuid === entity.uuid)) {
      removeUuids.add(entity.uuid);
    }
    setRememberUuid(entity?.uuid);
    props.onSelect(entity);
  }, [!!sessionInfo]);

  useEffect(() => {
    if (!yManager) return;

    return () => {
      const uuids = [...removeUuids.values()];
      Promise.allSettled(uuids.map(uuid => yManager.destroyEntry(uuid)))
        .catch(console.error);
    };
  }, [!!yManager]);

  const defaultUuids = useMemo<string[]>(() => {
    const result = new Set<string>();
    if (rememberUuid) result.add(rememberUuid);
    (sessionInfo?.entities || [])
      .map<[number, string]>(e => [e.entityId, e.entityUuid])
      .sort(byMapperFn(([id]) => id))
      .forEach(([_, uuid]) => result.add(uuid));
    return [...result.values()];
  }, [!!sessionInfo]);

  return <EntitySelectorBase
    selected={props.selected}
    onSelect={onSelect}
    options={options}
    entities={entities}
    defaultUuids={defaultUuids}
  />;
}

function ManagerEntitySelector(props: EntitySelectorProps) {
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { entities, options } = useMemo<{
    entities: Map<string, number>,
    options: EntityOption[]
  }>(() => {
    const manageableEntities = (sessionInfo?.entities || [])
      .filter(entity => entity.roles.includes('Manager') || entity.roles.includes('Admin'))
      .map(entity => ({ id: entity.entityId, uuid: entity.entityUuid, label: entity.name }));

    const entities = new Map<string, number>();
    for (const entity of manageableEntities) {
      entities.set(entity.uuid, entity.id);
    }

    return {
      entities,
      options: manageableEntities.map<EntityOption>(e => ({
        value: e.uuid,
        label: e.label
      }))
    };
  }, [sessionInfo?.entities]);

  return <EntitySelectorBase
    selected={props.selected}
    onSelect={props.onSelect}
    options={options}
    entities={entities}
  />;
}

function EntitySelectorBase(props: EntitySelectorProps & { options: EntityOption[], entities: Map<string, number>, defaultUuids?: string[] }) {
  const options = props.options;
  const entities = props.entities;
  const [selected, setSelected] = useState<EntityOption | null>(null);

  const selectOption = (option: EntityOption | undefined | null) => {
    if (option) {
      setSelected(option);
      const id = option ? entities.get(option.value) : undefined;
      props.onSelect(option && id ? { id, uuid: option.value }: undefined);
    } else {
      setSelected(null);
      props.onSelect(undefined);
    }
  };

  useEffect(() => {
    if (props.selected) {
      const match = options.find(o => o.value === props.selected);
      if (match) {
        setSelected(match);
        return;
      }
    }

    if (props.defaultUuids?.length) {
      for (const defaultUuid of props.defaultUuids) {
        const match = options.find(o => o.value === defaultUuid);
        if (match) {
          selectOption(match);
          return;
        }
      }
    }

    selectOption(options.at(0));
  }, [props.selected, selected, entities, options]);

  return <Select
    options={options}
    value={selected}
    placeholder={'Select an agency...'}
    onChange={selectOption}
    styles={{
      control: (baseStyles, state) => ({
        ...baseStyles,
        background: 'var(--bs-body-bg)',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgb(var(--bs-secondary-rgb))',
        borderRadius: 0,
        height: '100%',
        fontSize: '1rem'
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        zIndex: 10
      })
    }}
  />;
}
