import { useParams } from 'react-router-dom';
import { ShortId } from '@property-folders/common/util/url';
import { Maybe } from '@property-folders/contract';
import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';

export type EntitySettingsGroup =
  | 'email'
  | 'marketing'
  | 'reaforms'
  | 'greatforms'
  | 'portal'
  | 'portal-self-registration'
  | 'rules'
  | 'disclosure-of-benefits';

export interface EntitySettingsTabConfig {
  id: EntitySettingsGroup,
  title: string,
  preview?: {
    bg?: string
  },
  nyi?: boolean,
  pfOnly?: boolean
}
export const entitySettingsTabsConfig: Array<EntitySettingsTabConfig> = [
  { id: 'disclosure-of-benefits', title: 'Disclosure of benefits', pfOnly: true },
  { id: 'email', title: 'Email branding', preview: {}, pfOnly: true },
  { id: 'greatforms', title: 'Greatforms branding', preview: {}, pfOnly: true },
  { id: 'marketing', title: 'Marketing branding', preview: {}, nyi: true, pfOnly: true },
  { id: 'portal', title: 'Portal branding', preview: { bg: 'var(--clr-reaforms-charcoal)' }, pfOnly: true },
  { id: 'portal-self-registration', title: 'Portal self-registration branding', preview: {}, pfOnly: true },
  { id: 'reaforms', title: 'reaforms branding', preview: {} },
  { id: 'rules', title: 'Rules', nyi: true, pfOnly: true }
];

export function useEntitySettingsParams() {
  const largeScreen = useMediaQuery({ minWidth: BP_MINIMA.xl });
  const mobile = useMediaQuery({ maxWidth: BP_MINIMA.sm });
  const {
    entityUuidRaw,
    settingsGroup
  } = useParams();

  const entityUuid = entityUuidRaw && ShortId.toUuid(entityUuidRaw);
  const settingsGroupId = settingsGroup as Maybe<EntitySettingsGroup>;
  const settingsGroupTabConfig = entitySettingsTabsConfig.find(x => x.id === settingsGroupId);

  return {
    entityUuid,
    settingsGroupTabConfig,
    largeScreen,
    mobile
  };
}
