import { Button, Form, Modal } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { AsyncButton } from '@property-folders/components/dragged-components/AsyncButton';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';
import { FormCode, TransactionMetaData } from '@property-folders/contract';
import { useImmerYjs } from '@property-folders/components/hooks/useImmerYjs';
import { PropertyFormYjsDal, setAutoServeRecipeints } from '@property-folders/common/yjs-schema/property/form';
import { applyMigrationsV2 } from '@property-folders/common/yjs-schema';
import { MasterRootKey } from '@property-folders/contract/yjs-schema/property';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { keyBy, mapValues, startCase } from 'lodash';

export function Form1ReplaceModal({ type, onClose, onOk }: { type: 'order' | 'create' | 'upload', onClose: () => void, onOk: () => void }) {
  const { ydoc: yDoc, transactionMetaRootKey } = useContext(YjsDocContext);
  const { bindState: metaBindState } = useImmerYjs<TransactionMetaData>(yDoc, transactionMetaRootKey);
  const { data: meta } = metaBindState<TransactionMetaData>(m => m);
  const recipients = PropertyFormYjsDal.getFormFamilyServeRecipients(FormCode.Form1, meta);
  const localEntity = useEntity(meta?.entity?.id);
  const [reServe, setReserve] = useState<{[key:string]: boolean}>(localEntity?.signingOptions?.autoServeForm1 ? mapValues(keyBy(recipients, r=>r.id), ()=>true) : {});
  const [show, setShow] = useState(true);

  const handleCancel = () => {
    setShow(false);
    onClose();
  };

  const handleReplace = () => {
    if (!yDoc) return;

    applyMigrationsV2<TransactionMetaData>({
      doc: yDoc,
      docKey: MasterRootKey.Meta,
      typeName: 'Property',
      migrations: [{
        name: 'set auto re-serve for Form 1',
        fn: draft => {
          const toAutoServe = recipients?.filter(r => !!reServe?.[r.id])?.map(r => r.id)||[];
          setAutoServeRecipeints(draft, FormCode.Form1, toAutoServe);
        }
      }]
    });

    setShow(false);
    onOk();
  };

  return <Modal show={show} size='lg' onHide={handleCancel}>
    <Modal.Header closeButton>
      <div className='d-flex flex-column'>
        <Modal.Title>Replace active Form 1</Modal.Title>
      </div>
    </Modal.Header>
    <Modal.Body>
      <div className={'px-3'}>
        The following actions will apply:
        <ul>
          <li>The active Form 1 will be archived</li>
          {type === 'upload' && <li>You will upload a Form 1 PDF document. You can upload a fully signed and executed document, or a document that requires signing by the Vendor and/or the Agent</li>}
          {type === 'create' && <li>You will prepare a Form 1 document to be signed by the Vendor and the Agent</li>}
          {type === 'order' && <li>You will submit an order to Eckermann Property Forms to prepare and certify the Form 1 document on your behalf</li>}
        </ul>

        {!!recipients?.length &&
          <div>
            Re-serve the new document to the following recipients automatically:
            {recipients.map(r => <Form.Check
              id={`re-serve-${r.id}`}
              key={`re-serve-${r.id}`}
              label={r.name}
              className={'ms-3 mb-1'}
              checked={reServe?.[r.id]}
              onChange={()=>setReserve(e=> ({ ...e, [r.id]: !e?.[r.id] }))}
            >
            </Form.Check>)}
          </div>
        }
      </div>

    </Modal.Body>
    <Modal.Footer>
      <Button variant='outline-secondary' onClick={handleCancel}>Cancel</Button>
      <AsyncButton onClick={handleReplace}>{startCase(type)}</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
