import * as Y from 'yjs';
import './App.scss';
import './fonts/line-awesome/css/line-awesome.min.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { YManager } from '@property-folders/common/offline/yManager';
import Dexie from 'dexie';
import { IndexeddbPersistence } from 'y-indexeddb';
import { bind } from 'immer-yjs';
import { getPathParentAndIndex, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { seoFriendlySlug, ShortId } from '@property-folders/common/util/url';
import { fromBase64 } from 'lib0/buffer';
import { cloneDeep } from 'lodash';
import { OfflineProperties } from '@property-folders/common/offline/offlineProperties';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { MaterialisedPropertyData, YDocContentType } from '@property-folders/contract';
import { PathType } from '@property-folders/contract/yjs-schema/model';
import { Predicate } from '@property-folders/common/predicate';
import {
  FormCode,
  MasterRootKey,
  META_APPEND,
  TransactionMetaData
} from '@property-folders/contract/yjs-schema/property';
import { FileStorage } from '@property-folders/common/offline/fileStorage';
import { v4 } from 'uuid';
import { useOnline } from '@property-folders/components/hooks/useOnline';
import { Rum } from '@property-folders/components/telemetry/Rum';
import { handleNewForm } from '@property-folders/common/util/handleNewForm';
import { Awareness } from 'y-protocols/awareness';
import { NotesDisplay } from '~/NotesDisplay';
import { ValidSessionApp } from '~/ValidSessionApp';
import { UnauthenticatedRoutedApp } from '~/UnauthenticatedRoutedApp';
import { alwaysBypassRoutes } from '~/alwaysBypassRoutes';
import { Pdf } from '@property-folders/common/util/pdf-worker/Pdf';
export interface RouterData {
  transId: string,
  ydoc: Y.Doc,
  localProvider: IndexeddbPersistence,
  ydocStats: Y.Doc,
  localProviderStats: IndexeddbPersistence,
  awareness: Awareness,
}

declare global {
  interface Window {
    fnDev: any
    reaformsGlobal: {
      version?: any;
    }
  }
}

window.fnDev = window.fnDev || {};
window.reaformsGlobal = window.reaformsGlobal || {};
if (import.meta.env.DEV) {
// devs: you can call this when running locally so you get back a real session token via the proxy.
// supply your own email/password, as though it were the login screen.
  window.fnDev.login = AjaxPhp.login;
  window.fnDev.clearUntracked = async (agentId: number) => {
    const keepIds = new Set((await OfflineProperties.all(agentId)).map(i => i.id));
    const dbNames = await Dexie.getDatabaseNames();
    for (const name of dbNames) {
      if (keepIds.has(name)) {
        continue;
      }
      const uuidMatch = name.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i);
      if (!uuidMatch) {
        continue;
      }

      console.log(`deleting database ${name}`);
      await Dexie.delete(name);
    }
  };
  window.fnDev.seoFriendlySlug = seoFriendlySlug;
  window.fnDev.Y = Y;
  window.fnDev.ShortId = ShortId;
  window.fnDev.showPathDefinitionWarnings = true;

  const generateRootKeyList = async (idOrShort?: string): Promise<string[]> => {
    if (!idOrShort) {
      const BASEPATH = '/properties';
      idOrShort = window.location.pathname.replace(BASEPATH, '').split('/').filter(Predicate.isTruthy)[0];
      console.log(idOrShort);
    }
    const iid = ShortId.toUuid(idOrShort);

    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(iid, doc);
    await localProvider.whenSynced;

    const list = [`${MasterRootKey.Data}`, `${MasterRootKey.Meta}`];
    const knownRoots = (doc.getMap(MasterRootKey.Meta).toJSON() as TransactionMetaData).sublineageRoots;
    list.push(...(knownRoots??[]).map(key=>([key,key+META_APPEND])).flat());
    return list;
  };

  const loadProperty = (idOrShort?: string) => {
    if (!idOrShort) {
      const BASEPATH = '/properties';
      idOrShort = window.location.pathname.replace(BASEPATH, '').split('/').filter(Predicate.isTruthy)[0];
      console.log(idOrShort);
    }
    const iid = ShortId.toUuid(idOrShort);
    console.log(iid);
    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(iid, doc);

    return { doc, localProvider, iid };
  };

  window.fnDev.loadLocalProperty = async (idOrShort?: string, dumpKey?: number | string) => {

    const { doc, localProvider, iid } = loadProperty(idOrShort);
    if (dumpKey != null) {
      if (typeof dumpKey === 'number' && dumpKey > 1) {
        const keyList = await generateRootKeyList(iid);
        dumpKey = keyList[dumpKey];
      }
      await new Promise((resolve, reject) => {
        localProvider.whenSynced.then(resolve).catch(reject);
      });
      return window.fnDev.dumpDoc(localProvider.doc, dumpKey);
    }
    return { doc,  localProvider };
  };

  window.fnDev.loadLocalDoc = (id: string) => {
    const doc = new Y.Doc();
    const localProvider = new IndexeddbPersistence(id, doc);
    return { doc, localProvider };
  };

  window.fnDev.dumpKnownRootKeys = async (idOrShort?: string) => {
    const list = await generateRootKeyList(idOrShort);
    for (const [i,v] of list.entries()) {
      if (i === 0) {
        console.log(i+':', v, 'Main data root key');
        continue;
      }
      if (i === 1) {
        console.log(i+':', v, 'Main meta root key');
        continue;
      }
      if (v.endsWith(META_APPEND)) {
        const baseId = v.slice(0,v.length-META_APPEND.length);
        console.log(i+':', v, ShortId.fromUuid(baseId));
        continue;
      }
      console.log(i+':', v, ShortId.fromUuid(v));
    }
  };
  window.fnDev.dumpDoc = (doc: Y.Doc, key: string | 0 | 1 = 0) => {
    if (typeof key === 'number') {
      if (key === 1) return doc.getMap(MasterRootKey.Meta).toJSON();
      return doc.getMap(MasterRootKey.Data).toJSON();
    }
    return doc.getMap(key).toJSON();
  };

  window.fnDev.createDebugSublineage = async (idOrShort?: string) => {
    // It is worth noting that this may not be fully synchronised with the network ydoc
    const { doc, localProvider, iid } = loadProperty(idOrShort);
    await localProvider.whenSynced;
    const newRootKey = v4();
    const newMetaRootKey = newRootKey+META_APPEND;
    const parentMetaBinder = bind(doc.getMap(MasterRootKey.Meta));
    const now = new Date();
    const newDataBinder = bind(doc.getMap(newRootKey));
    const newMetaBinder = bind(doc.getMap(newMetaRootKey));
    parentMetaBinder.update((draft: TransactionMetaData)=>{
      if (!Array.isArray(draft.sublineageRoots)) {
        draft.sublineageRoots = [];
      }
      draft.sublineageRoots.push(newRootKey);
    });
    const parentMeta = parentMetaBinder.get() as TransactionMetaData;
    newMetaBinder.update((draft:TransactionMetaData) => {
      draft.createdUtc = now.toISOString();
      draft.entity = parentMeta.entity;
      draft.creator = parentMeta.creator;

    });
    handleNewForm(doc, FormCode.RSC_ContractOfSale, undefined, {}, newRootKey, newMetaRootKey);
    newDataBinder.update((draft: MaterialisedPropertyData) => {
      Object.assign(draft, doc.getMap(MasterRootKey.Data).toJSON());
    });
  };

  window.fnDev.undefinePath = (doc: Y.Doc, key: string = MasterRootKey.Data, path: PathType) => {
    const pathStr = normalisePathToStr(path);
    const binder = bind(doc.getMap(key));
    binder.update((draft) => {
      const { indexer, parent } = getPathParentAndIndex(pathStr, draft);
      delete parent[indexer];
      // Console.log exists because we cannot return from an update to print to the devtools console.
      // returning a value here will affect the update. So we need to log here
      console.log(cloneDeep(draft));
    });
  };
  window.fnDev.setPath = (doc: Y.Doc, key: string = MasterRootKey.Data, path: PathType, value: any) => {
    const pathStr = normalisePathToStr(path);
    const binder = bind(doc.getMap(key));
    binder.update((draft) => {
      const { indexer, parent } = getPathParentAndIndex(pathStr, draft);
      parent[indexer] = value;
      // Console.log exists because we cannot return from an update to print to the devtools console.
      // returning a value here will affect the update. So we need to log here
      console.log(cloneDeep(draft));
    });
  };
  window.fnDev.Y = Y;
  window.fnDev.loadDocFromBase64 = (state: string) => {
    const doc = new Y.Doc();
    Y.applyUpdate(doc, fromBase64(state));
    return doc;
  };
  window.fnDev.hasPending = YManager.hasPendingOutboundMessages;
  window.fnDev.printGc = () => {
    YManager.instance().printGc();
  };
  window.fnDev.gc = () => {
    return YManager.instance().garbageCollect();
  };

  window.addEventListener('beforeunload', () => {
    YManager.unbind();
  });

  window.fnDev.setNoSigSuggestion = (setVal: boolean) => {
    window.fnDev.noSigSuggestion = setVal == undefined ? true : setInterval;
  };

  window.fnDev.focusDebuggerEnabled = false;
  window.fnDev.enableFocusDebugger = () => {
    window.fnDev.focusDebuggerEnabled = true;
  };

  window.fnDev.yApplyProperty = (idOrShort: string | undefined, fn: (state: MaterialisedPropertyData) => void) => {
    const iid = idOrShort
      ? ShortId.toUuid(idOrShort)
      : window.location.pathname
        .split('/')
        .filter(Predicate.isTruthy).map(s => {
          try {
            return ShortId.toUuid(s);
          } catch {
            return undefined;
          }
        })
        .filter(Predicate.isTruthy)[0];
    if (!iid) {
      throw new Error('Could not determine/parse out id');
    }

    const { doc, localProvider } = YManager.instance().get(iid, YDocContentType.Property, {});

    localProvider.whenSynced.finally(() => {
      console.log('localProvider synced');
      if (!localProvider.synced) {
        console.error('local provider not synced');
        return;
      }

      const binder = bind<MaterialisedPropertyData>(doc.getMap(MasterRootKey.Data));
      binder.update(fn);
      console.log('applied');
    });
  };

  window.fnDev.readFileFromStore = (fileKey: string) => {
    return FileStorage.read(fileKey);
  };

  window.fnDev.deleteFileFromStore = (fileKey: string) => {
    return FileStorage.delete(fileKey);
  };

  window.fnDev.queueDownload = FileStorage.queueDownload;
  window.fnDev.writeFile = FileStorage.write;

  // This should only pass if we're in dev mode
  window.fnDev.generateDoc = (contentArrayOrWholeObject: any) => {
    const dd = Array.isArray(contentArrayOrWholeObject)
      ? {
        content: contentArrayOrWholeObject
      }
      : contentArrayOrWholeObject;
    const pdf = new Pdf();
    pdf
      .prepare({
        headingColour: '#000000',
        lineColour: '#000000',
        agencyContact: { agencyEmail: 'test@eckermanns.com.au', agencyName: 'fnDev Agency', agencyPhone: '0400 000 000', agencyRla: '123 456' }
      }, {}, false)
      .generateBlob(dd, (blob) => {
        const nc = document.createElement('a');
        nc.setAttribute('href', URL.createObjectURL(blob));
        nc.setAttribute('target', '_blank');
        document.querySelector('body')?.appendChild(nc);
        nc.click();
        nc.remove();
      });
  };

}

export type AgentInfoState = {
  agentId: number;
  agentUuid: string;
  offlineProperties: boolean;
  propertyFoldersEnabled: boolean;
  newFormsCreated: boolean;
  enableWebSockets: boolean;
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export const App = () => {
  const url = new URL(window.location.href);
  const operateUnauthenticated = alwaysBypassRoutes.findIndex(r => url.pathname.startsWith(`/${r}`)) !== -1;
  const [needReload, setNeedReload] = useState(false);
  window.reaformsGlobal.version = import.meta.env.GIT_VERSION;
  const setVersion = async () => {
    try {
      window.reaformsGlobal.version = (await (await fetch(`/version.json?id=${__COMMIT_HASH__}`)).json()).version;
    } catch {
      console.error('Could not retrieve current deployed version');
    }
  };
  setVersion();
  const isOnline = useOnline();
  setInterval(async () => {
    if (isOnline) {
      try {
        const latestVersion = (await (await fetch(`/version.json?id=${__COMMIT_HASH__}`)).json()).version;
        const noneReactPages = [
          '/legacy/forms.php',
          '/forms.php',
          '/legacy/remotecompletion.php',
          '/remotecompletion.php',
          '/legacy/iframe_editor.php',
          '/iframe_editor.php',
          '/legacy/form_iframe.php',
          '/form_iframe.php'
        ];
        if (window.reaformsGlobal.version != latestVersion && !noneReactPages.some((noneReactPathname) => url.pathname.startsWith(noneReactPathname))) {
          setNeedReload(true);
        }
      } catch {
        console.error('Could not retrieve current deployed version for forcing reload');
      }

    }
  }, 5*60*1000);

  if (url.pathname.startsWith('/forms.php') && url.searchParams.has('AuthToken') && url.searchParams.has('UserToken')) {
    window.location.href = LinkBuilder.loginPage(
      `${url.pathname}?DocumentID=${url.searchParams.get('DocumentID')}`,
      {
        AuthToken: url.searchParams.get('AuthToken') as string,
        UserToken: url.searchParams.get('UserToken') as string
      }
    ).toString();

    return null;
  }

  if (url.pathname.startsWith('/forms.php') && url.searchParams.has('code')) {
    window.location.href = LinkBuilder.loginPage(
      `${url.pathname}?DocumentID=${url.searchParams.get('DocumentID')}`,
      {
        code: url.searchParams.get('code') as string
      }
    ).toString();

    return null;
  }

  useEffect(() => {
    // if the app's just starting up, there won't be any background tasks yet.
    // just in case they didn't clean up properly (i.e. HMR shenanigans), do it for them now.
    YManager.clearLock();
    FileSync.clearLock();
  }, []);

  return <>
    <Rum />
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        {// Prevent any form of login checks to auth/session/info by not going in via ValidSessionApp
          operateUnauthenticated
            ? <UnauthenticatedRoutedApp routes={alwaysBypassRoutes}/>
            : <ValidSessionApp needsReload={needReload} />
        }
        <NotesDisplay/>
      </Provider>
    </QueryClientProvider>
  </>;
};

export default App;
