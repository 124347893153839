import { useContext } from 'react';
import { EntitySettingsContext } from '~/pages/settings/EntitySettingsContext';
import { Button } from 'react-bootstrap';

export function EntityLargeScreenPreviewClickHandler({
  children,
  previewKey,
  onPreviewKey
}: React.PropsWithChildren<{ previewKey: string, onPreviewKey: (key: string) => void }>) {
  const { largeScreen } = useContext(EntitySettingsContext);
  return largeScreen
    ? <div
      onClick={() => onPreviewKey(previewKey)}
      onFocus={() => onPreviewKey(previewKey)}>{children}</div>
    : <>{children}</>;
}

export function EntitySmallScreenPreviewButton({
  previewKey,
  onPreviewKey
}: { previewKey: string, onPreviewKey: (key: string) => void }) {
  const { largeScreen } = useContext(EntitySettingsContext);
  return largeScreen
    ? <></>
    : <Button variant='link' title='Preview' size='sm' className='px-0 py-0 border-0 bg-transparent link-secondary' onClick={() => onPreviewKey(previewKey)}>Preview</Button>;
}
