import { useMediaQuery } from 'react-responsive';
import { BP_MINIMA } from '@property-folders/common/data-and-text/bootstrapBreakpoints';
import { useWindowSize } from 'react-use';
import { useDevicePreferences } from '@property-folders/components/hooks/useDevicePreferences';
import React, { useEffect, useRef, useState } from 'react';
import clsJn from '@property-folders/common/util/classNameJoin';
import {
  SetupSideNavMenuSizingContext
} from '@property-folders/components/dragged-components/SideNavMenuSizingContext';
import { SideNavMenu } from '@property-folders/components/dragged-components/SideNavMenu/SideNavMenu';
import { ErrorBoundary } from '@property-folders/components/telemetry/ErrorBoundary';
import { WholePageApology } from '@property-folders/components/display/errors/major-crash';
import { useMatches } from 'react-router-dom';
import { SideNavFallback } from '@property-folders/components/display/errors/sidebar';
import { checkIfIndexedDBShimNeeded } from '@property-folders/components/shims/IndexedDB/checkIfIndexedDBShimNeeded';

export function AppContainer({ children, showSideBar = true, ...props }: {
  children: any,
  onLogoutClick: () => void;
  showSideBar?: boolean
}) {
  const matches = useMatches();
  const matchReset = matches.map(match => match.pathname);
  const isMinWidthLg = useMediaQuery({ minWidth: BP_MINIMA.lg });
  const windowSize = useWindowSize();
  const { devicePreferences, setDevicePreferences } = useDevicePreferences();
  const [toggle, setToggle] = useState(devicePreferences?.sidebar.collapseAt ? windowSize.width > devicePreferences.sidebar.collapseAt : isMinWidthLg);
  const sideNavRef = useRef<HTMLElement>(null);
  useEffect(() => {
    checkIfIndexedDBShimNeeded()
      .then(needed => {
        if (needed) {
          console.warn('Using IndexedDB Blob workaround');
        }
      });
  }, []);

  const handleShowSideMenu = () => {
    setToggle(cv => {
      const newExpand = !cv;
      setDevicePreferences(s => {
        s.sidebar = {
          collapseAt: newExpand ? windowSize.width - 1 : Number.MAX_SAFE_INTEGER
        };
        return s;
      });
      return newExpand;
    });
  };

  useEffect(() => {
    setToggle(devicePreferences?.sidebar.collapseAt ? windowSize.width > devicePreferences.sidebar.collapseAt : isMinWidthLg);
  }, [windowSize, devicePreferences]);

  return <>
    <div className={clsJn('d-flex flex-column fill-space', showSideBar ? 'sidenav' : 'sidenav-hidden')}>
      <div className={clsJn('below-header flex-grow-1 h-100 overflow-hidden', toggle && 'expand')}>
        <SetupSideNavMenuSizingContext sideNavRef={sideNavRef}>
          <ErrorBoundary FallbackComponent={WholePageApology} resetKeys={matchReset}>
            <div className='content-container'>
              {/*stop chrome autocomplete*/}
              <form style={{ display: 'none' }}></form>
              {children}
            </div>
          </ErrorBoundary>
          {showSideBar && <ErrorBoundary FallbackComponent={SideNavFallback}>
            <div className={clsJn('expansion-mask', toggle && 'expand')} onClick={() => setToggle(false)}></div>
            <SideNavMenu ref={sideNavRef} expanded={toggle} onExpandToggle={handleShowSideMenu} onLogoutClick={props.onLogoutClick}></SideNavMenu>
          </ErrorBoundary>}
        </SetupSideNavMenuSizingContext>
      </div>
    </div>
  </>;
}
