import { useEffect, useState } from 'react';
import { Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import PasswordResetForm from './PasswordResetForm';
import NewPasswordForm from './NewPasswordForm';
import LoginForm from './LoginForm';
import { useSearchParams } from 'react-router-dom';
import { LinkBuilder } from '@property-folders/common/util/LinkBuilder';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';

import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { FullPageLoading } from '@property-folders/components/dragged-components/FullPageLoading';

enum PageView {
  Login = 'login',
  ForgotPassword = 'forgotpassword',
  NewPassword = 'newpassword'
}

const LoginPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [currentView, setCurrentView] = useState<PageView>(searchParams.get('ResetToken') ? PageView.NewPassword : PageView.Login);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    switch (currentView) {
      case PageView.Login:
        loginHandler();
        break;
      case PageView.ForgotPassword:
        forgotPasswordHandler();
        break;
      case PageView.NewPassword:
        newPasswordHandler();
        break;
      default:
        loginHandler();
        break;
    }
  };
  useEffect(() => {
    setInvalidLogin(false);
  }, [username, password]);

  const loginHandler = async () => {
    const data = new FormData();
    data.append('email', username);
    data.append('password', password);
    const returnPath = searchParams.get('returnPath');

    setLoading(true);
    const loginUrl = `${LinkBuilder.ajax}?action=login`;
    const response = await WrappedFetch.json<{success: boolean;}>(loginUrl, {
      method: 'POST',
      body: data
    });

    if (response?.success) {
      if (returnPath && returnPath.startsWith('/')) {
        window.location.replace(returnPath);
      }
      else {
        window.location.replace('/');
      }
    } else {
      setInvalidLogin(true);
    }
    setLoading(false);
  };

  const newPasswordHandler = async () => {
    const data = new FormData();
    data.append('password', password);
    data.append('repeatpassword', newPassword);

    setLoading(true);
    const response = await (await fetch(`/legacy/ajax.php?action=newpassword&ResetToken=${searchParams.get('ResetToken')}`, {
      method: 'POST',
      body: data
    })).json();

    if (response.success) {
      setAlert({
        type: 'success',
        message: <>Your password has been reset. Please login with your new credentials.</>
      });
    } else {
      setAlert({
        type: 'danger',
        message: <>Your password reset token has expired. Please request a new password reset link.</>
      });
      searchParams.delete('ResetToken');
      setSearchParams(searchParams);
    }
    setCurrentView(PageView.Login);
    setPassword('');
    setNewPassword('');
    setLoading(false);
  };

  const forgotPasswordHandler = async () => {
    const data = new FormData();
    data.append('email', username);
    setLoading(true);

    await fetch('/legacy/ajax.php?action=forgotpassword', {
      method: 'POST',
      body: data
    });

    setLoading(false);
    setCurrentView(PageView.Login);
    setAlert({
      type: 'success',
      message: <>If we recognise your email address, you will be sent a password reset email. If you have not received an email, please contact us <a href={'mailto:support@reaforms.com.au'}>support@reaforms.com.au</a> or call <a href={'tel:0881661188'}>08 8166 1188</a>.</>
    });
  };

  const [alert, setAlert] = useState({
    type: '',
    message: <></>
  });

  const code = searchParams.get('code');
  if (code) {
    AjaxPhp.loginWithCode(code).then((response) => {
      if (response?.success) {
        const returnPath = searchParams.get('returnPath');
        window.location.replace(returnPath ?? '/');
      }
    });

    return <FullPageLoading />;
  }

  return (
    <Row className='d-flex align-items-center justify-content-center g-0' style={{ height: '100%', backgroundColor: '#f4f5fa' }}>
      <Col sm={8} md={8} lg={6} xl={4}>
        <form onSubmit={(event) => handleSubmit(event)} className='Auth-form p-4' style={{ height: 'auto', boxShadow: '0 10px 18px 0 rgba(62, 57, 107, 0.2)', backgroundColor: '#FFFFFF' }}>
          <div className='Auth-form-content'>
            <div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
              <img src='/legacy/images/reaforms_logo_strap.svg' alt='reaforms' style={{ maxHeight: '80px' }}></img>
            </div>
            {alert.type && <Alert variant={alert.type} className={'mb-4 mt-4'} onClose={() => setAlert({ type: '', message: <></> })} dismissible>
              {alert.message}
            </Alert>}
            {currentView === PageView.ForgotPassword && <PasswordResetForm
              username={username}
              setUsername={setUsername}
            />}
            {currentView === PageView.NewPassword && <NewPasswordForm
              password={password}
              setPassword={setPassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
            />}
            {
              currentView === PageView.Login && <LoginForm username={username} password={password} setPassword={setPassword} setUsername={setUsername}/>
            }
            { invalidLogin && currentView === PageView.Login && <strong className='text-danger float-end'>Login is invalid</strong>}
            <br/>
            <Row>
              <Col xs={{ span: 8, offset: 2 }}>
                <div className='d-grid gap-2 mt-3'>
                  <Button className='rounded' type='submit' style={{ borderColor: '#df5d2c', backgroundColor: '#FFFFFF', color: '#df5d2c' }}>
                    {
                      [PageView.ForgotPassword, PageView.NewPassword].includes(currentView) &&  (
                        <>{loading ? <Spinner animation="border" style={{ height: '1.2em', width: '1.2em' }}/> : 'Reset password'}</>
                      )
                    }
                    {
                      [PageView.Login].includes(currentView) &&  (
                        <>{loading ? <Spinner animation="border" style={{ height: '1.2em', width: '1.2em' }}/> : 'Login'}</>
                      )
                    }
                  </Button>
                </div>
              </Col>
            </Row>
            <hr/>
            <p className='forgot-password text-right mt-2' style={{ fontSize: '15pt' }}>
              { [PageView.ForgotPassword, PageView.NewPassword].includes(currentView) && <Button variant='link' style={{ fontSize: '18px', textDecoration: 'none' }} onClick={() => setCurrentView(PageView.Login)}>
                Login
              </Button>
              }
              { [PageView.Login].includes(currentView) && <Button variant='link' style={{ fontSize: '18px', textDecoration: 'none' }} onClick={() => setCurrentView(PageView.ForgotPassword)}>
                Reset password
              </Button>
              }
            </p>
          </div>
        </form>
      </Col>
      <iframe style={{ display: 'none' }} src={LinkBuilder.reaformsFromRoot('/epf_logout.php')}/>
    </Row>
  );
};

export default LoginPage;
