import React from 'react';
import { entitySettingsTabsConfig, useEntitySettingsParams } from '~/pages/settings/config';
import { NavLink } from 'react-router-dom';
import { WaitForLoadedYDoc } from '@property-folders/components/context/YManagerContext';
import { AwarenessData, YDocContentType } from '@property-folders/contract';
import { SetupNetStateContext } from '@property-folders/components/dragged-components/NetStateContext';
import { EntitySettingsEntity, EntitySettingsRootKey } from '@property-folders/contract/yjs-schema/entity-settings';
import { SetupNetStateWritingYjsDocContext } from '@property-folders/components/form-gen-util/yjsStore';
import { useOfflineAwareness } from '@property-folders/components/hooks/useAwareness';
import * as Y from 'yjs';
import { initialPresence } from '~/pages/TransactionHomePage';
import { RoomProvider } from '@y-presence/react';
import { SetupDebouncedAwarenessContext } from '@property-folders/components/context/DebouncedAwarenessContext';
import * as awarenessProtocol from 'y-protocols/awareness';
import { Container, ListGroup } from 'react-bootstrap';
import { FormContext } from '@property-folders/components/context/FormContext';
import { FormContextType } from '@property-folders/common/types/FormContextType';
import { entitySettings } from '@property-folders/contract/yjs-schema/model/field';
import clsJn from '@property-folders/common/util/classNameJoin';
import { entitySettingsFieldGroups } from '@property-folders/contract/yjs-schema/model/form';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';

export function EntitySettingsNavMenuPage(props: React.PropsWithChildren) {
  const { entityUuid } = useEntitySettingsParams();

  if (!entityUuid) return <></>;

  // in newer react-router-dom unstable_viewTransition is an optional flag on Link/NavLink/navigate
  return <WaitForLoadedYDoc
    yDocId={entityUuid}
    yDocType={YDocContentType.EntitySettings}
    loadingElement={<div>Loading...</div>}
    children={yDoc => <SetupContexts yDoc={yDoc} entityUuid={entityUuid}>
      <EntitySettingsNavItems {...props} />
    </SetupContexts>}
  />;
}

function EntitySettingsNavItems(props: React.PropsWithChildren) {
  const { settingsGroupTabConfig, largeScreen } = useEntitySettingsParams();
  const { value: settings } = useLightweightTransaction<EntitySettingsEntity>({ myPath: '' });
  const enablePf = Boolean(settings?.useNewPropertyTransactions);

  return <Container fluid className='d-flex flex-row h-100 g-0'>
    {Boolean(largeScreen || !settingsGroupTabConfig) && <ListGroup className='d-flex flex-column nav mt-4 mx-3' style={{ width: largeScreen ? undefined : '100%' }}>
      {entitySettingsTabsConfig.map(tab => (tab.nyi || (!enablePf && tab.pfOnly)) ? <React.Fragment key={tab.id} /> : (
        <NavLink key={tab.id} to={tab.id} className={clsJn('list-group-item list-group-item-action', tab.nyi && 'disabled')}>
          {tab.title}
        </NavLink>))}
    </ListGroup>}
    <div className='flex-grow-1'>
      {props.children}
    </div>
  </Container>;
}

const formContext: FormContextType = {
  formId: 'entity-settings',
  formName: 'entity-settings',
  wizardTitle: '',
  printTitle: '',
  transactionRules: entitySettings,
  metaRules: { _type: 'Map' },
  fieldGroups: entitySettingsFieldGroups
};
function SetupContexts({
  yDoc,
  entityUuid,
  children
}: React.PropsWithChildren<{ yDoc: Y.Doc, entityUuid: string }>) {
  const awareness = useOfflineAwareness(yDoc) || new awarenessProtocol.Awareness(yDoc);
  return <SetupNetStateContext
    ydoc={yDoc}
    transactionRootKey={EntitySettingsRootKey.Main}
  >
    <SetupNetStateWritingYjsDocContext
      ydoc={yDoc}
      awareness={awareness}
      docName={entityUuid}
      transactionRootKey={EntitySettingsRootKey.Main}
      transactionMetaRootKey={EntitySettingsRootKey.Main}
    >
      <FormContext.Provider value={formContext}>
        <RoomProvider<AwarenessData> awareness={awareness} initialPresence={initialPresence}>
          <SetupDebouncedAwarenessContext delay={0}>
            {children}
          </SetupDebouncedAwarenessContext>
        </RoomProvider>
      </FormContext.Provider>
    </SetupNetStateWritingYjsDocContext>
  </SetupNetStateContext>;
}
