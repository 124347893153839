import { createContext, useEffect, useMemo, useState } from 'react';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { EntitySettingsTabConfig } from '~/pages/settings/config';
import { v4 } from 'uuid';

export interface EntitySettingsContextData {
  afterBreadcrumbs?: HTMLDivElement | null;
  entityUuid?: string,
  entityPhpInfo?: {
    urlLogo?: string,
    logo?: Blob,
    urlPurchaserHeader?: string
    purchaserHeader?: Blob,
    entityId: number,
    reload: () => void
  },
  entityPhpInfoStatus: 'loading' | 'loaded',
  largeScreen: boolean,
  settingsGroupTabConfig?: EntitySettingsTabConfig
}
export const EntitySettingsContext = createContext<EntitySettingsContextData>({ largeScreen: false, entityPhpInfoStatus: 'loading' });

export function SetupEntitySettingsContext(props: React.PropsWithChildren< Omit<EntitySettingsContextData, 'entityPhpInfo' | 'entityPhpInfoStatus'>>) {
  const [entityBuster, setEntityBuster] = useState(v4());
  const [entityPhpInfo, setEntityPhpInfo ] = useState<EntitySettingsContextData['entityPhpInfo']>(undefined);
  const [entityPhpInfoLoading, setEntityPhpInfoLoading] = useState(true);

  useEffect(() => {
    if (!props.entityUuid) {
      setEntityPhpInfo(undefined);
      return;
    }

    const entityUuid = props.entityUuid;

    const ac = new AbortController();
    setEntityPhpInfoLoading(true);
    (async () => {
      const result = await AjaxPhp.getEntitySettingsInfo({ entityUuid, signal: ac.signal });
      if (ac.signal.aborted) return;
      if (!result?.success) return;

      const [
        logo,
        purchaserHeader
      ] = await Promise.all([
        fetchImage(result.urlLogo),
        fetchImage(result.urlPurchaserHeader)
      ]);

      setEntityPhpInfo({
        urlLogo: logo ? URL.createObjectURL(logo) : undefined,
        logo,
        urlPurchaserHeader: purchaserHeader ? URL.createObjectURL(purchaserHeader) : undefined,
        purchaserHeader,
        entityId: result.entityId,
        reload: () => setEntityBuster(v4())
      });
      setEntityPhpInfoLoading(false);
    })()
      .finally(() => setEntityPhpInfoLoading(false));
    return () => {
      ac.abort();
    };
  }, [props.entityUuid, entityBuster]);

  const data = useMemo<EntitySettingsContextData>(() => {
    return {
      afterBreadcrumbs: props.afterBreadcrumbs,
      entityUuid: props.entityUuid,
      largeScreen: props.largeScreen,
      settingsGroupTabConfig: props.settingsGroupTabConfig,
      entityPhpInfo,
      entityPhpInfoStatus: entityPhpInfoLoading ? 'loading' : 'loaded'
    };
  }, [
    props.afterBreadcrumbs,
    props.entityUuid,
    props.largeScreen,
    props.settingsGroupTabConfig,
    entityPhpInfo,
    entityPhpInfoLoading
  ]);

  return <EntitySettingsContext.Provider value={data}>
    {props.children}
  </EntitySettingsContext.Provider>;
}

async function fetchImage(url: string | undefined) {
  if (!url) return undefined;
  const r = await fetch(url);
  const contentType = r.headers.get('content-type');
  if (!contentType || !contentType.startsWith('image/')) return undefined;
  const blob = await r.blob();
  return blob.type.startsWith('image/')
    ? blob
    : undefined;
}
