import { EntityLargeScreenPreviewClickHandler, EntitySmallScreenPreviewButton } from '~/pages/settings/PreviewHandlers';
import { WizardStepPage } from '@property-folders/components/dragged-components/Wizard/WizardStepPage';
import React, { useEffect, useState } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { EntityBrandFormConfig, EntitySettingsEntity } from '@property-folders/contract/yjs-schema/entity-settings';
import { WrField } from '@property-folders/components/dragged-components/form/CommonComponentWrappers';
import { reaformsOrange } from '@property-folders/common/visual';
import { usePdfWorker } from '@property-folders/components/hooks/usePdfWorker';
import { defaultFormBrandConfig } from '@property-folders/common/util/pdfgen/constants';
import { useAgentSessionPreviewEmailContact } from '@property-folders/components/display/EmailPreview';
import { v4 } from 'uuid';
import { ContentType, TitleInclusionState } from '@property-folders/contract';
import { CoverSheet, PdfWorkerDocumentDefinition } from '@property-folders/common/util/pdf/pdf-worker-types';
import { useEntityLogo } from '@property-folders/components/hooks/useEntityLogo';
import { PDFViewer, ZoomMode } from '@property-folders/components/dragged-components/PDFViewer/PDFViewer';
import { SetupPdfLoadStateContext } from '@property-folders/components/context/pdfLoadStateContext';
import { Col, Row } from 'react-bootstrap';

export enum GreatformsBrandingSettingsPreviewKey {
  AdvancedForms = 'advancedForms'
}

export function GreatformsBrandingSettingsEdit({ onPreviewKey }: { onPreviewKey: (key: string) => void }) {
  const { value: entity } = useLightweightTransaction<EntitySettingsEntity>({
    myPath: ''
  });
  return <div>
    <EntityLargeScreenPreviewClickHandler onPreviewKey={onPreviewKey} previewKey={GreatformsBrandingSettingsPreviewKey.AdvancedForms}>
      <WizardStepPage
        name='advancedForms'
        label='Advanced Forms'
        icon='email'
        headerContent={<EntitySmallScreenPreviewButton onPreviewKey={onPreviewKey} previewKey={GreatformsBrandingSettingsPreviewKey.AdvancedForms}/>}
      >
        <Row>
          <Col size={12}>
            <h4>Headings</h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.form' myPath='headingColour' defaultValue={reaformsOrange} /></Col>
          <Col size={6}><WrField.ColourPicker name='Separators' parentPath='brand.form' myPath='lineColour' defaultValue={reaformsOrange} /></Col>
        </Row>
        <Row className='mt-3'>
          <Col size={12}>
            <h4>Signing Button</h4>
          </Col>
        </Row>
        <Row>
          <Col size={6}><WrField.ColourPicker name='Text' parentPath='brand.signing.button' myPath='foregroundColour' defaultValue={'#ffffff'} /></Col>
          <Col size={6}><WrField.ColourPicker name='Background' parentPath='brand.signing.button' myPath='backgroundColour' defaultValue={reaformsOrange} /></Col>
        </Row>
        {entity?.usePfRemoteCompletion
          ? <>
            <Row className='mt-3'>
              <Col size={12}>
                <h4>Remote Completion</h4>
              </Col>
            </Row>
            <Row>
              <Col size={6}><WrField.ColourPicker name='Input Highlight Border' parentPath='brand.signing.remoteCompletion' myPath='borderColour' defaultValue={reaformsOrange} /></Col>
              <Col size={6}></Col>
            </Row>
          </>
          : <></>}
      </WizardStepPage>
    </EntityLargeScreenPreviewClickHandler>
  </div>;
}

export function GreatformsBrandingSettingsPreview({ previewKey }: { previewKey: string }) {
  switch (previewKey) {
    case GreatformsBrandingSettingsPreviewKey.AdvancedForms:
    default:
      return <AdvancedFormsPreview />;
  }
}

function AdvancedFormsPreview() {
  const { value: entity } = useLightweightTransaction<EntitySettingsEntity>({
    myPath: ''
  });
  const contact = useAgentSessionPreviewEmailContact();
  const entityLogo = useEntityLogo(entity?.entityId);
  const pdfWorker = usePdfWorker();
  const [previewUrl, setPreviewUrl] = useState('');

  useEffect(() => {
    if (!pdfWorker) return;

    const ac = new AbortController();
    (async () => {
      console.log('calling generatePdf...');
      const data = await pdfWorker.generatePdf(getSampleSaaDefinition(
        entity?.brand?.form || defaultFormBrandConfig,
        { agencyLogoImage: entityLogo },
        {
          headline: 'Sales Agency Agreement',
          agentName: contact.name,
          documentLabel: 'Sales Agency Agreement'
        }), 'preview');

      if (ac.signal.aborted) return;
      setPreviewUrl(URL.createObjectURL(new Blob([data], { type: ContentType.Pdf })));
    })().catch(console.error);

    return () => ac.abort();
  }, [
    entity?.brand?.form?.headingColour,
    entity?.brand?.form?.lineColour
    // todo: logos
  ]);

  const fgSign = entity?.brand?.signing?.button?.foregroundColour || '#ffffff';
  const bgSign = entity?.brand?.signing?.button?.backgroundColour || reaformsOrange;
  const inputOutline = entity?.brand?.signing?.remoteCompletion?.borderColour || reaformsOrange;
  return <div className='w-100 p-3'>
    {previewUrl && <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
      <SetupPdfLoadStateContext>
        <PDFViewer
          bookmark=''
          pdfUrl={previewUrl}
          filename={'preview.pdf'}
          renderTextLayer={false}
          standalonePreview={true}
          useLoadSuccessForCompletion={true}
          activeViews={1}
          zoomMode={ZoomMode.Manual}
          pageWrapElement={({ children, zoom }) => {
            return <div style={{
              width: '100%',
              height: '100%',
              position: 'relative'
            }}>
              {children}
              <div
                className='d-flex flex-row align-items-center justify-content-center'
                style={{
                  position: 'absolute',
                  top: '75%',
                  left: -20,
                  aspectRatio: 3,
                  width: `${zoom * 100}px`,
                  fontSize: `${zoom * 20}px`,
                  color: fgSign,
                  background: bgSign,
                  clipPath: 'polygon(0px 0px, 85% 0px, 100% 50%, 85% 100%, 0px 100%)'
                }}>
                <span className='fw-bold'>Start</span>
              </div>
              {entity?.usePfRemoteCompletion
                ? <div style={{
                  position: 'absolute',
                  top: '77%',
                  left: '52%',
                  fontSize: `${zoom * 20}px`,
                  outline: `3px solid ${inputOutline}`,
                  padding: '2px'
                }}>Enter text</div>
                : <></>}
            </div>;
          }}
        />
      </SetupPdfLoadStateContext>
    </div>}
  </div>;
}

function getSampleSaaDefinition(brand: EntityBrandFormConfig, objects: any, meta: CoverSheet): PdfWorkerDocumentDefinition {
  const addrId = v4();
  return {
    property: {
      id: v4(),
      saleAddrs: [{
        id: addrId,
        streetAddr: '123 Evergreen Terrace',
        hundred: 'Adelaide',
        suburb: 'Adelaide',
        lga: 'Adelaide',
        subStateAndPost: 'Adelaide, SA 5000',
        fromLssa: false,
        irrigationArea: ''
      }],
      saleTitles: [{
        id: v4(),
        linkedAddresses: [addrId],
        title: 'CT 5555/555',
        isWhole: true,
        isNone: false,
        valuations: ['999999999'],
        fromLssa: false,
        subTitles: [{
          id: v4(),
          portionType: TitleInclusionState.whole,
          lotid: '123',
          lot: 'Allotment',
          planid: '12345',
          plan: 'Deposited Plan'
        }]
      }],
      compareAddrs: [],
      disableCompareAlreadyProvided: true
    },
    signers: [],
    formInstance: undefined,
    formType: 'SampleSaaPDF',
    annexures: [],
    noBoldContentMode: false,
    printTitle: 'Sample SAA',
    objects,
    formFamilyState: {},
    brand,
    meta,
    options: {}
  };
}
